<template>
  <div class="page13">
    <img class="page13-img" src="../../assets/pages/obj_13.png" alt="">
  </div>
</template>

<script>
export default {
  name: 'page13',
}
</script>

<style scoped lang="scss">
.page13 {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  .page13-img {
    height: 100%;
  }
}
</style>